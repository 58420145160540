import type { FC } from "react";
import React from "react";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import translate from "src/lib/translate";
import { OnboardingTasks } from "src/ui/components/OnboardingTasks/OnboardingTasks";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";

const OnboardingTasksPopup: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  return (
    <InDialog
      popup
      title={translate("gettingStarted")}
      onClose={onClose}
      returnUrl={returnUrl}
      simple
    >
      <OnboardingTasks onClose={onClose} />
    </InDialog>
  );
};

export default OnboardingTasksPopup;
